// colors in :root and the $color list should match
// to keep colors up to date in Storybook
// and all color variables should start with --color-
// ---------------------------------------------------------
// Run `yarn sass-variables` to keep colors updated in Storybook.
// ---------------------------------------------------------

:root {
  --color-off-black: #0c0c0c;
  --color-anvil: #282b2e;
  --color-slate: #5a5f66;
  --color-pebble: #808890;
  --color-prime-gray: #c2cbd1;
  --color-cement: #e7edef;
  --color-cloud: #f2f4f4;
  --color-warm-gray: #f3f3f3;
  --color-white: #fff;
  --color-midnight: #00292f;
  --color-dusk: #004b57;
  --color-lagoon: #007383;
  --color-ocean: #00a0b2;
  --color-prime-teal: #54d3de;
  --color-prime-teal-light: #89eaf0;
  --color-ice: #abf4f7;
  --color-ice-light: #d9feff;
  --color-gold: #abb222;
  --color-moss: #cedb1f;
  --color-butternut: #d9ed21;
  --color-amp-yellow: #eaff61;
  --color-limoncello: #edff95;
  --color-deep-red: #af3708;
  --color-saturated-red: #d93d0d;
  --color-saturated-red-a11y: #d93d0d;
  --color-prime-red: #f98;
  --color-heat-map-red: #f4d0cb;
  --color-red-tint: #f7eded;
  --color-deep-green: #317a1c;
  --color-saturated-green: #75c163;
  --color-prime-green: #a4e298;
  --color-heat-map-green: #caefc5;
  --color-green-tint: #e9f6ea;
  --color-meringue: #f6ffdc;
  --color-deep-pink: #ce49a9;
  --color-saturated-pink: #ff7dd7;
  --color-deepest-pink: #a82684;
  --color-prime-pink: #f4bee9;
  --color-light-pink: #f2d7ee;
  --color-pink-tint: #f7e8f5;
  --color-deep-purple: #5f61b2;
  --color-saturated-purple: #8285c6;
  --color-prime-purple: #b5b5e0;
  --color-light-purple: #d9d9f4;
  --color-purple-tint: #ebebf7;
  --color-deep-orange: #bd5f0a;
  --color-saturated-orange: #d9923f;
  --color-prime-orange: #f4b679;
  --color-light-orange: #f6d9b9;
  --color-orange-tint: #f9ede4;
}

// ---------------------------------------------------------

$grayscale: (
  '--color-off-black': #0c0c0c,
  '--color-anvil': #282b2e,
  '--color-slate': #5a5f66,
  '--color-pebble': #828890,
  '--color-prime-gray': #c2cbd1,
  '--color-cement': #e7edef,
  '--color-cloud': #f2f4f4,
  '--color-warm-gray': #f3f3f3,
  '--color-white': #fff
);

// ---------------------------------------------------------

$teals: (
  '--color-midnight': #00292f,
  '--color-dusk': #004b57,
  '--color-lagoon': #007383,
  '--color-ocean': #00a0b2,
  '--color-prime-teal': #54d3de,
  '--color-ice': #abf4f7,
  '--color-ice-light': #d9feff
);

// ---------------------------------------------------------

$yellows: (
  '--color-gold': #abb222,
  '--color-moss': #cedb1f,
  '--color-butternut': #d9ed21,
  '--color-amp-yellow': #eaff61,
  '--color-limoncello': #edff95,
  '--color-meringue': #f6ffdc
);

// ---------------------------------------------------------

$reds: (
  '--color-deep-red': #af3708,
  '--color-saturated-red': #f75b2b,
  '--color-saturated-red-a11y': #d93d0d,
  '--color-prime-red': #f98,
  '--color-heat-map-red': #f4d0cb,
  '--color-red-tint': #f7eded
);

// ---------------------------------------------------------

$greens: (
  '--color-deep-green': #317a1c,
  '--color-saturated-green': #75c163,
  '--color-prime-green': #a4e298,
  '--color-heat-map-green': #caefc5,
  '--color-green-tint': #e9f6ea
);

// ---------------------------------------------------------

$pinks: (
  '--color-deeest--pink': #a82684,
  '--color-deep-pink': #ce49a9,
  '--color-saturated-pink': #ff7dd7,
  '--color-prime-pink': #f4bee9,
  '--color-light-pink': #f2d7ee,
  '--color-pink-tint': #f7e8f5
);

// ---------------------------------------------------------

$oranges: (
  '--color-deep-orange': #bd5f0a,
  '--color-saturated-orange': #d9923f,
  '--color-prime-orange': #f4b679,
  '--color-light-orange': #f6d9b9,
  '--color-orange-tint': #f9ede4
);
